import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const Enterprisesolution = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

       
          <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12 sm:p-4 ">
            <div className="flex-1 flex-col items-left sm:items-center justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight" id="payment-gateway">
        Paysys Payment <br/> Gateway
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        PaySys Payment Gateway offers a secure, reliable, and efficient solution for processing online transactions. Designed to support businesses of all sizes, our gateway ensures seamless integration, real-time processing, and advanced fraud protection, making it easier for you to accept payments and grow your business globally.
        </p>
        {/* <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button> */}
    </div>
    <div className="flex-1">
            <img src={Image.vector} alt="handphone" />

          </div>
          </div>

        <div className="flex flex-col items-center justify-center  h-[500px] bg-heroPaysys bg-auto bg-no-repeat bg-center bg-cover">
          <h1 className="text-largeCard  font-sans font-black text-center leading-extraTight">
            Simple, easy payments
          </h1>
          <p className="text-mediumCard  font-inter font-medium text-center leading-extraTight mt-1">

            Building a business is hard. Getting paid shouldn't be with
          </p>
          <div className=" my-8">
            <div className="h-[60px] w-[120px]">
              <img src={Image.paysyslogo} alt="handphone" />

            </div>
          </div>
        </div>
    
      <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px]  lg:p-12 sm:p-4 ">
      <div className="flex-1 pl-12">
        <img src={Image.mobilewallet} alt="handphone" />
      </div>
     
        <div className="flex-1 flex-col items-left justify-center flex-wrap pl-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight" id="mobile-wallet">
    Mobile Wallet
    </p>
    <p className="text-[#838383] text-left font-regular text-medium">
    PaySys Mobile Wallet empowers you to manage your finances on the go. With secure, instant access to your funds, you can make payments, transfer money, and track transactions—all from your smartphone. Simplify your financial life with PaySys Mobile Wallet, wherever you are.  </p>
    {/* <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button> */}
        </div>

      </div>
   
      <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12 sm:p-4 ">
     
        <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight" id="agency-banking">
    Paysys Agency<br/>  Banking Services 
    </p>
    <p className="text-[#838383] text-left font-regular text-medium">
    PaySys Agency Banking Services provide a comprehensive solution for financial institutions and businesses to extend their reach and enhance customer service. Offering streamlined processes for deposits, withdrawals, and account management, our services empower agents to deliver efficient and secure banking experiences, driving growth and accessibility in your community.   </p>
    {/* <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button> */}
</div>
<div className="flex-1">
        <img src={Image.agency} alt="handphone" />

      </div>
      </div>

      <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px]  lg:p-12 sm:p-4 ">
      <div className="lg:flex-1">
     <img src={Image.pos1} alt="handphone" />

   </div>
     <div className="flex-1 flex-col items-left justify-center flex-wrap lg:pr-24">
 
 <p className="text-large  font-sans font-black text-left leading-extraTight" id="point-of-sale">
 Paysys Point-of-Sale(POS)
 </p>
 <p className="text-[#838383] text-left font-regular text-medium">
 PaySys Point-of-Sale (POS) system offers a robust, user-friendly solution for managing in-store transactions. With features like real-time inventory tracking, seamless payment processing, and comprehensive sales reports, our POS system helps streamline operations, improve customer service, and enhance business efficiency.  </p>
 {/* <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
 </button> */}
</div>

   </div>



   <Footer />
   
      </main>

     
    </div>
  );
};

export default Enterprisesolution;
