import { FaFacebook, FaTwitter } from 'react-icons/fa6'
import { IoLogoLinkedin } from 'react-icons/io'
import { Image } from '../../assets';

const Footer = () => {
    const product: any = [
        {
          product: [
            "Payment",
            "Products",
            "Solutions",
            "SME ",
            "API Documentation",
          ],
          company: ["About us", "Careers", "Press", "News"],
          resources: ["Blog", "Events", "Help centre", "Support"],
          legal: ["Terms", "Privacy", "Settings", "Contact"],
          social: ["Twitter", "LinkedIn", "Facebook"],
        },
      ];
    
  return (
    <footer>
        <div className="px-[7%] bg-[#1d2939] py-[50px] grid gap-[10%] md:grid-cols-[1fr_2fr] ">
          <div className="grid h-fit gap-[10px] ">
            <img src={Image.paysyslogo} alt="LOGO" className='sm:w-[120px]'/>
            <p className="text-[16px] text-[#EAECF0]">
              Paysys is a payment solution commited to building solutions to enable businesses, merchants
              and individuals , to accept payments instantly and efficiently at the click of a button. 

            </p>
          </div>
          <div className="grid gap-[20px] md:grid-cols-3 lg:grid-cols-5 items-start justify-start pb-[100px]">
            {Object.keys(product[0]).map((category, index) => (
              <div
                key={index}
                className={`grid gap-[20px] text-[#EAECF0] text-[16px]`}
              >
                <p className="text-[#98A2B3]">
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </p>
                {product[0][category].map((item: any, idx: any) => (
                  <p key={idx}>{item}</p>
                ))}
              </div>
            ))}
          </div>
        </div>
        
        <div className="px-[7%] py-[22px] bg-[#2682A1] flex items-center justify-between flex-wrap gap-[20px] text-white text-[16px]">
          <p>© 2024 Paysys. All rights reserved.</p>
          <div className="flex items-center gap-[20px] text-[19px]">
            <FaTwitter />
            <IoLogoLinkedin />
            <FaFacebook />
          </div>
          <div className="grid">
          <p>57 takum street Garki Abuja</p>
          <p>Email:contact@paysys.ng</p>
          <p>Phone:+2349168350058</p>

            </div>
          
            
          
        </div>
      </footer>
  )
}

export default Footer