import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const About = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

      <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] lg:p-12  ">
        <div className="lg:px-[14%] mb-12 ">
              <h1 className="text-large  font-sans font-black text-left leading-extraTight">
              Powering a new generation of businesses in Africa              </h1>
              <p className="text-[#838383] text-left font-regular text-medium">
              At Paysys, we are dedicated to fueling the growth and innovation of African enterprises. By providing cutting-edge financial solutions and technologies, we empower businesses to reach new heights. Our mission is to support entrepreneurs, SMEs, and large corporations alike in overcoming challenges, optimizing their operations, and expanding their horizons. Together, we are building a brighter future for the African business landscape.
              </p>
          </div>
         

    

  

           <div className="flex flex-row items-center justify-center gap-4 w-full mt-8">
           <img src={Image.product5} alt="handphone" />
          </div>


        </div>

      <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] lg:p-12  ">
        <div className="lg:px-[14%] mb-12 ">
        <p className="text-large  font-sans font-black text-left leading-extraTight">
         The Foundation of <br/>Our  Fintech Journey
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        At Paysys, we believe in building more than just financial products—we're crafting a future where finance is smarter, more inclusive, and seamlessly integrated into everyday life. Our guiding principles are the cornerstone of everything we do, driving us to innovate, serve, and exceed expectations.   </p>
    
          </div>
         


          <div className="flex lg:flex-row sm:flex-col sm:justify-center items-center lg:justify-between gap-4 w-full mt-12">
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%]  bg-[#F68D27] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[150px]">
        
            <p className="text-[#011F44] text-left font-bold font-sans text-[45px] leading-tight">
             Our Vision
                </p>
                <p className="text-[#6B5A74] text-left font-semibold font-inter text-[22px] mt-2">
                Paysys helps many of the largest corporate and government organizations in Nigeria get paid quickly and securely.
                </p>
            
            </div>
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%] bg-[#00ADEF]  bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[150px]">
        
            <p className="text-[#011F44] text-left font-bold font-sans text-[45px] leading-tight">
                Our Mission
                </p>
                <p className="text-[#6B5A74] text-left font-semibold font-inter text-[22px] mt-2">
                our mission is to empower individuals and communities by
           providing accessible financial services tailored to their needs.
                </p>
            
        </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:justify-center items-center lg:justify-between gap-4 w-full mt-12">
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%] bg-[#00ADEF] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[150px]">
        
            <p className="text-[#011F44] text-left font-bold font-sans text-[45px] leading-tight">
   Our Goals
        </p>
        <p className="text-[#6B5A74] text-left font-semibold font-inter text-[22px] mt-2">
        Paysys helps many of the largest corporate and government organizations in Nigeria get paid quickly and securely.
        </p>
      
            </div>
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%] bg-[#F68D27]  bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[150px]">
        
            <p className="text-[#011F44] text-left font-bold font-sans text-[45px] leading-tight">
        Our Purpose
        </p>
        <p className="text-[#6B5A74] text-left font-semibold font-inter text-[22px] mt-2">
        We strive to foster financial inclusion, promote entrepreneurship,
        and create lasting social impact.
        </p>
    
    </div>
          </div>

           <div className="flex flex-row items-center justify-between gap-4 w-full mt-8">
          
          </div>


        </div>

        <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] lg:p-12 py-8">
        <div className="lg:flex-1">
            <img src={Image.switchingpayment} alt="handphone" />

          </div>
            <div className="flex-1 flex-col items-left justify-center flex-wrap lg:pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Switching Payment <br/> through Innovation
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Paysys broad network and robust payments platform have been instrumental to the development of the Nigerian payments ecosystem and provide Interswitch with credibility to expand across Nigeria.​
        </p>
    
    </div>
   
          </div>
    
  
    
        <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] lg:p-12 bg-[#5FB3A0] py-8">
        <div className="lg:px-[14%] mb-12 ">
              <h1 className="text-large  font-sans text-white font-bold text-left leading-extraTight">
           Our Core Values
              </h1>
              <p className="text-medium font-inter text-white font-medium text-left leading-extraTight mt-2">
              Our success is built firmly on our commitment to our core values.
              </p>
          </div>
         
          <div className="flex sm:flex-col gap-8 sm:w-full">

            <div className="flex-1 bg-[#fff] rounded-[40px] flex flex-col items-center justify-center flex-wrap lg:px-24 py-24">
        <p className="text-[#011F44] text-left font-inter font-bold text-[36px] leading-tight">
    Innovation
        </p>
</div>

<div className="flex-1 bg-[#fff] rounded-[40px] flex flex-col items-center justify-center flex-wrap lg:px-28 py-24">
        <p className="text-[#011F44] text-left font-inter font-bold text-[36px] leading-tight">
    Trust
        </p>
</div>

<div className="flex-1 bg-[#fff] rounded-[40px] flex flex-col items-center justify-center flex-wrap lg:px-28 py-24">
        <p className="text-[#011F44] text-left font-inter font-bold text-[36px] leading-tight">
      Excellence
        </p>
</div>

</div>
     
        </div>
        <Footer />
      </main>


    </div>
  );
};

export default About;
