import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const Brands = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

      <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] p-12">
 
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Paysys Payment <br/> Gateway
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Welcome to Paysys Mobile Wallet, your one-stop solution for seamless, secure, and convenient digital transactions
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button>
    </div>
    <div className="flex-1">
            <img src={Image.vector} alt="handphone" />

          </div>
   
          </div>



          
      <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] p-12">
        <div className="flex-1">
            <img src={Image.brand1} alt="handphone" />

          </div>
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Payment Links
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Simplify the way you get paid with Paysys Payment Links. Designed for businesses of all sizes, our payment links offer a convenient and efficient method to request payments from your customers, no matter where they are.
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button>
    </div>
   
          </div>


           
          <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] p-12">
  
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Payment Links
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Simplify the way you get paid with Paysys Payment Links. Designed for businesses of all sizes, our payment links offer a convenient and efficient method to request payments from your customers, no matter where they are.
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button>
    </div>
    <div className="flex-1">
            <img src={Image.brand2} alt="handphone" />

          </div>
   
          </div>

      <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] p-12  ">
        <div className="lg:px-[14%] mb-12 ">
              <h1 className="text-large  font-sans font-black text-left leading-extraTight">
              Switching Payment Future through Innovation
              </h1>
              <p className="text-medium font-inter font-medium text-left leading-extraTight">
              Paysys broad network and robust payments platform have been instrumental to the development of the Nigerian payments ecosystem and provide Interswitch with credibility to expand across Nigeria.​
              </p>
          </div>
         


    

  

           <div className="flex flex-row items-center justify-center gap-4 w-full mt-8">
           <img src={Image.brand3} alt="handphone" />
          </div>
          <div className="flex flex-row items-center justify-center gap-4 w-full mt-8">
           <img src={Image.brand4} alt="handphone" />
          </div>

        </div>

        <div className="lg:px-[7%] text-center  grid gap-[100px]">
        <div className=" bg-[#00ADEF] border rounded-[40px] flex gap-16 flex-row items-center justify-between gap-4 w-full mt-8 lg:px-12">
            <div className="flex flex-col items-left justify-center flex-wrap w-[50%]   h-[500px] ">
        <p className="text-[#ffffff] text-left font-inter font-bold text-[64px] leading-tight">
        <span className="text-[#F8BC3B]"> Connect</span> to your easy transaction life
        </p>
        <p className="text-[#ffffff] text-left font-inter font-regular text-[18px]">
        We create solutions that enable individuals and communities prosper across Nigeria.
        </p>
       
    </div>
    <div className="mb-8">
            <img src={Image.checkbox} alt="handphone" />

          </div>
          </div>
          
        </div>



      <Footer />      </main>
    </div>
  );
};

export default Brands;
