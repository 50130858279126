import React, { useEffect, useRef, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CiUnlock } from "react-icons/ci";
import { PiWallet } from "react-icons/pi";
import { FaRegAddressCard } from "react-icons/fa";
import { LiaWeightSolid } from "react-icons/lia";
import { IoBagOutline } from "react-icons/io5";
import { Image } from "../../assets";

const NavBar = () => {
  const navItems = ["Product", "Why Paysys", "Resourses", "Developers", "Company ",];
  const [isOpen, setisOpen] = useState(false);
  function toggleDropdown() {
    setisOpen(!isOpen);
  }
  const [product, setproduct] = useState("Enterprise Solution");
  const productArr = ["Enterprise Solution", "Payment Solution"];
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef: any = useRef(null);
  const dropdownRefs: any = useRef<(HTMLDivElement | null)[]>([]);

  // useEffect(() => {
  //   const handleClickOutside = (index:any, event:any) => {

  //     const navbarItem = document.getElementById(`navItem-${selectedIndex}`);
  //     console.log(navbarItem,'the nav item')
  //     console.log(dropdownRefs.current[selectedIndex], dropdownRefs)
  //     console.log(selectedIndex,'clicking outside')
  //     if (
  //       navbarItem &&
  //       navbarItem.contains(event.target) &&
  //       !(dropdownRefs.current[selectedIndex] && dropdownRefs.current[selectedIndex].contains(event.target))


  //     ) {

  //       setSelectedIndex(-1);
  //     }
  //   };

  //   const clickHandler = (event:any) => {
  //     handleClickOutside(selectedIndex, event);
  //   };


  //   window.addEventListener("click", clickHandler);


  //   return () => {
  //     window.removeEventListener("click", clickHandler);
  //   };
  // }, []);


  const handleItemClick = (index: any, e: any) => {
    console.log(index, 'when clicking')
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Close the dropdown if clicked again
    } else {
      setSelectedIndex(index); // Open the dropdown for the clicked item
    }
    // handleClickOutside(index,e);

  };


  const navigate = useNavigate()

  const enterpriseSolution = [{ icon: <CiUnlock className="text-[#EB6A03] m-auto" />, title: 'Paysys Payment gateway', descrp: 'Easy payment online', navigate: '/enterprise-solution#payment-gateway' }, { icon: <PiWallet className="text-[#EB6A03] m-auto" />, title: 'Paysys Mobile Wallet', descrp: 'Easy payment online', navigate: '/enterprise-solution#mobile-wallet' }, { icon: <LiaWeightSolid className="text-[#EB6A03] m-auto" />, title: 'Paysys Point-of-sale (POS)', descrp: 'Easy payment online', navigate: '/enterprise-solution#point-of-sale' }, { icon: <IoBagOutline className="text-[#EB6A03] m-auto" />, title: 'Paysys Agency Banking Services', descrp: 'Easy payment online', navigate: '/enterprise-solution#agency-banking' },]
  const smeSolution = [{ icon: '', title: 'Online Checkout', descrp: 'For small and enterprise business', navigate: '/payment-solution' }, { icon: '', title: 'Payment links', descrp: 'Sell online without a website Create a payment link with just a few clicks.', navigate: '/payment-link' }, { icon: '', title: 'Virtual terminals', descrp: 'Create a payment link with just a few clicks.', navigate: '/payment-solution#virtual-terminal' }]

  const DeveloperApi = [{ title: 'API documentation', text: 'Paysys payment gateway', navigate: '' }]
  const CompanyApi = [{ icon: <FaRegAddressCard />, title: 'Company', text: 'Our company', navigate: '/' }]

  // const WhyPaysys=[{title:'About',text:'Why Paysys',navigate:'/about'},{title:'Brands',text:'seamless, secure, and convenient digital transactions',navigate:'/brands'},{title:'Leadership',text:'The Paysys leader board list',navigate:'/leadership'},{title:'Goverance',text:'Cooperate Governance Law that guides us',navigate:'/governance'},{title:'Careers',text:'Join Paysys',navigate:'/career'}]
  const WhyPaysys = [{ title: 'About', text: 'Why Paysys', navigate: '/about' }, { title: 'Leadership', text: 'The Paysys leader board list', navigate: '' }, { title: 'Careers', text: 'Join Paysys', navigate: '' }]

  const helpApi = [{ title: 'FAQs', text: 'Quick help', navigate: '/faqs' }, { title: 'Contact Us', text: 'For our services', navigate: '/contact-us' }]

  const navItemdropdown: any = selectedIndex === 1 ? WhyPaysys : selectedIndex === 3 ? DeveloperApi : selectedIndex === 4 ? CompanyApi
    : selectedIndex === 2 && helpApi

  return (
    <nav className="py-[15px] px-[7%] flex items-center justify-between bg-white  z-50 fixed w-full top-0 ">
      <img src={Image.paysyslogo} width={150} alt="logo" onClick={() => navigate('/')} className='cursor-pointer sm:w-[120px]' />

      <div className="hidden md:flex items-center gap-[20px]">
        {navItems.map((val, index) => (
          <div
            className=" text-[#0C394B] text-[18px] relative cursor-pointer"
            key={index}>
            <div className="flex items-center" onClick={(e: any) => handleItemClick(index, e)} id={`navItem-${index}`} ref={(ref) => (dropdownRefs.current[index] = ref)}>
              <p >{val}</p>
              <RiArrowDownSLine />
            </div>
            {selectedIndex === index && (
              <div className={`absolute top-full ${selectedIndex === 1 || selectedIndex === 4 || selectedIndex === 5 || selectedIndex === 3 || selectedIndex === 2 ? 'min-w-[35vw] lg:min-w-[400px]' : 'min-w-[600px]'}  bg-white left-0 shadow-md rounded-lg `}  >
                {/* Dropdown content here */}
                {selectedIndex === 0 ? (
                  <div className="grid grid-cols-[2fr_3fr]" >
                    <div className="p-[20px] rounded-l-lg bg-[#D7DFE136]  bg-opacity-[21%] ">
                      <div className="grid gap-[20px] h-fit">
                        {productArr.map((val, index) => (
                          <div
                            className={`${product === val
                                ? "text-[#0C394B] p-[10px]  h-fit whitespace-nowrap rounded-md bg-[#E6E6E6] font-bold"
                                : "text-[#07222D] text-[16px]"
                              }`}
                            key={index}
                            onClick={() => setproduct(val)}
                          >
                            <p>{val}</p>
                          </div>
                        ))}

                      </div>
                    </div>
                    <div className="bg-white rounded-r-lg p-[20px] grid gap-[20px]">

                      {product === "Enterprise Solution" ? enterpriseSolution.map((val, index) => (
                        <div className="flex gap-[20px] items-center " key={index} onClick={() => navigate(`${val.navigate}`)}>
                          <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit">
                            {/* <img src={val.icon} alt="" /> */}
                            {val.icon}
                          </div>

                          <div className="grid ">
                            <p className="text-[#115570] text-[16px] font-bold">{val.title}</p>
                            <p className="text-[#0C394B] text-[14px]">{val.descrp}</p>
                          </div>
                        </div>

                      )) : smeSolution.map((val, index) => (
                        <div className="flex gap-[20px] items-center " key={index} onClick={() => navigate(`${val.navigate}`)} >
                          <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit"></div>

                          <div className="grid ">
                            <p className="text-[#115570] text-[16px] font-bold">{val.title}</p>
                            <p className="text-[#0C394B] text-[14px]">{val.descrp}</p>
                          </div>
                        </div>

                      ))}

                    </div>
                  </div>
                ) : <div className="grid gap-[20px] min-w-[35vw] lg:min-w-[400px]  p-[20px]" >
                  {navItemdropdown?.map((val: any, index: any) => (
                    <div className="flex gap-2 items-start" onClick={() => navigate(`${val.navigate}`)} key={index}>
                      <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit"></div>

                      <div className="grid gap-1">
                        <p className="text-[#0C394B] text-[16px] font-bold">{val.title}</p>
                        <p className="text-[#0C394B] text-[14px]">{val.text}</p>
                      </div>
                    </div>

                  ))}
                </div>}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="hidden md:block">

        <button className="border border-textGrey bg-textGrey text-white rounded-full px-16 py-3">
          Create account
        </button>


      </div>

      <button className="md:hidden ">
        <div
          className="relative w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
          onClick={toggleDropdown}
        >
          <span
            className={`absolute h-0.5 w-5 bg-secondary transform transition duration-300 ease-in-out ${isOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
              }`}
          ></span>
          <span
            className={`absolute h-0.5 bg-secondary transform transition-all duration-200 ease-in-out ${isOpen ? "w-0 opacity-50" : "w-5 delay-200 opacity-100"
              }`}
          ></span>
          <span
            className={`absolute h-0.5 w-5 bg-secondary transform transition duration-300 ease-in-out ${isOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
              }`}
          ></span>
        </div>
      </button>

      {/* small devices navbar */}
      <div
        className={`md:hidden absolute w-[100%] bottom-[0]  h-fit border-black  transition-transform duration-75 px-[8%] py-[20px] z-10 left-0  grid rounded-b-lg bg-white gap-[20px] ${isOpen ? "translate-y-full" : "translate-y-[-100vh]  "
          }`}
      >
        {/* <div className="shadow-2xl drop-shadow-lg w-full border-[.5px]"></div> */}
        {navItems.map((val, index) => (
          <div className="grid" key={index}>
            <div className="flex justify-between cursor-pointer" onClick={(e: any) => handleItemClick(index, e)} id={`navItem-${index}`} ref={(ref) => (dropdownRefs.current[index] = ref)}>
              <p>{val}</p>
              <RiArrowDownSLine />
            </div>
            {selectedIndex === index && (
              <div className={`w-[100%]  bg-white md:hidden grid rounded-lg `}  >
                {/* Dropdown content here */}
                {selectedIndex === 0 ? (
                  <div className="grid" >
                    <div className="p-[20px] rounded-l-lg bg-[#D7DFE136]  bg-opacity-[21%] ">
                      <div className="grid gap-[20px] h-fit">
                        {productArr.map((val, index) => (
                          <div
                            className={`${product === val
                                ? "text-[#0C394B] p-[10px]  h-fit whitespace-nowrap rounded-md bg-[#E6E6E6] font-bold"
                                : "text-[#07222D] text-[16px]"
                              }`}
                            key={index}
                            onClick={() => setproduct(val)}
                          >
                            <p>{val}</p>
                          </div>
                        ))}

                      </div>
                    </div>
                    <div className="bg-white rounded-r-lg p-[20px] grid gap-[20px]">

                      {product === "Enterprise Solution" ? enterpriseSolution.map((val, index) => (
                        <div className="flex gap-[20px] items-center " key={index} onClick={() => navigate(`${val.navigate}`)}>
                          <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit">
                            {/* <img src={val.icon} alt="" /> */}
                            {val.icon}
                          </div>

                          <div className="grid ">
                            <p className="text-[#115570] text-[16px] font-bold">{val.title}</p>
                            <p className="text-[#0C394B] text-[14px]">{val.descrp}</p>
                          </div>
                        </div>

                      )) : smeSolution.map((val, index) => (
                        <div className="flex gap-[20px] items-center " key={index} onClick={() => navigate(`${val.navigate}`)} >
                          <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit"></div>

                          <div className="grid ">
                            <p className="text-[#115570] text-[16px] font-bold">{val.title}</p>
                            <p className="text-[#0C394B] text-[14px]">{val.descrp}</p>
                          </div>
                        </div>

                      ))}

                    </div>
                  </div>
                ) : <div className="grid gap-[20px] min-w-[35vw] lg:min-w-[400px]  p-[20px]" >
                  {navItemdropdown?.map((val: any, index: any) => (
                    <div className="flex gap-2 items-start" onClick={() => navigate(`${val.navigate}`)} key={index}>
                      <div className="p-2 rounded-full bg-[#ECFAFF61] bg-opacity-[38%] w-[45px] h-[45px] max-h-fit"></div>

                      <div className="grid gap-1">
                        <p className="text-[#0C394B] text-[16px] font-bold">{val.title}</p>
                        <p className="text-[#0C394B] text-[14px]">{val.text}</p>
                      </div>
                    </div>

                  ))}
                </div>}
              </div>
            )}
          </div>
        ))}
        <div className="h-fit">


          <button className="border border-textGrey bg-textGrey text-white rounded-full px-16 py-3">
            Login
          </button>


        </div>
      </div>
    </nav>
  );
};

export default NavBar;
