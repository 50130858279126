 import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const Productsolution = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

        

      <div className="flex flex-col items-center justify-center  h-[500px] bg-heroPaysys bg-auto bg-no-repeat bg-center bg-cover mt-[200px]">
          <h1 className="text-largeCard  font-sans font-black text-center leading-extraTight">
            Simple, easy payments
          </h1>
          <p className="text-mediumCard  font-inter font-medium text-center leading-extraTight mt-1">

            Building a business is hard. Getting paid shouldn't be with
          </p>
          <div className=" my-8">
            <div className="h-[60px] w-[120px]">
              <img src={Image.paysyslogo} alt="handphone" />

            </div>
          </div>
        </div>


          <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12">
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Online Checkout
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Experience the ease and efficiency of Paysys Online Checkout, a seamless solution designed to streamline the payment process for small and medium businesses
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button>
    </div>
    <div className="flex-1">
            <img src={Image.onlinecheckout} alt="handphone" />

          </div>
          </div>
      

      <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12">
      <div className="flex-1 pl-12">
        <img src={Image.paymentlink} alt="handphone" />
      </div>
     
        <div className="flex-1 flex-col items-left justify-center flex-wrap pl-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight">
Payment Links
    </p>
    <p className="text-[#838383] text-left font-regular text-medium">
    Simplify the way you get paid with Paysys Payment Links. Designed for businesses of all sizes, our payment links offer a convenient and efficient method to request payments from your customers, no matter where they are.  </p>
    <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button>
        </div>

      </div>


      <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12" >
     
        <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight">
Virtual Terminals
    </p>
    <p className="text-[#838383] text-left font-regular text-medium" id="virtual-terminal">
    perience the ultimate in payment flexibility with Paysys Virtual Terminals. Designed to cater to businesses that require a versatile, remote payment solution, our Virtual Terminals empower you to process transactions securely from any device with internet access
    </p>
    <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button>
</div>
<div className="flex-1">
        <img src={Image.virtualterminal} alt="handphone" />

      </div>
      </div>
 
      <Footer />
      </main>

     
    </div>
  );
};

export default Productsolution;
