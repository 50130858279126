import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import AboutMe from "./Pages/AboutMe";
// import Compliance from "./Pages/Compliance";
 import FAQs from "./Pages/faqs";
import LandingPage from "./Pages/Login";
// import PaymentSolution from "./Pages/PaymentSolution";
 import Products from "./Pages/Products";
 import Enterprisesolution from "./Pages/Enterprisesolution";
 import Productsolution from "./Pages/Productsolution";
 import PaymentLink from "./Pages/PaymentLink";
 import About from "./Pages/About";
 import Brands from "./Pages/Brands";
 import Leadership from "./Pages/Leadership";
//  import Governance from "./Pages/faqs";
 import Career from "./Pages/Career";


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<LandingPage />} />
      {/* <Route path="/about" element={<AboutMe />} />
      <Route path="/compliance" element={<Compliance />} />
     
      <Route path="/paymentSolution" element={<PaymentSolution />} />*/}
       <Route path="/faqs" element={<FAQs />} />
       <Route path="/contact-us" element={<FAQs />} />
      <Route path="/product" element={<Products />} /> 
      <Route path="/enterprise-solution" element={<Enterprisesolution />} /> 
      <Route path="/payment-solution" element={<Productsolution />} /> 
      <Route path="/payment-link" element={<PaymentLink />} /> 
      <Route path="/about" element={<About />} /> 
      <Route path="/brands" element={<Brands />} /> 
      <Route path="/leadership" element={<Leadership />} /> 
      {/* <Route path="/governance" element={<Governance />} />  */}
      <Route path="/career" element={<Career />} /> 
    </Routes>
    </Router>
  );
}

export default App;
