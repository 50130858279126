import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const faqs = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">



        <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] p-12 bg-[#E3ECFF] ">
          <div className="px-[14%] mb-12 ">
            <h1 className="text-large  font-sans font-black text-center leading-extraTight">
              Our most FAQs
            </h1>
            <p className="text-medium font-inter font-medium text-center leading-extraTight">
              Do you have a question you will like to ask us ?
            </p>
            <p className="text-[28px] font-inter font-medium text-center leading-extraTight mt-4">
              for your easy transaction or easy business life?
            </p>
          </div>

          <div className="flex flex-row items-center justify-center gap-4 w-full">

            <div className="">
              <p>  Email:contact@paysys.ng</p>
            </div>
            <div className="">
              <p>Phone:+2349168350058</p>

            </div>
          </div>


        </div>
        <Footer />
      </main>


    </div>
  );
};

export default faqs;
