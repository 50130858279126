import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const   Leadership = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

     

      <div className="flex flex-col items-left justify-center flex-wrap mt-[180px] pl-[190px] px-4 ">
        <div className=" mb-12 ">
              <h1 className="text-large  font-sans font-black text-left leading-extraTight">
              The Team
              </h1>
              <p className="text-medium font-inter font-medium text-left leading-extraTight">
              We’re a diverse, close-knitted team on an adventure to build <br/> something enduring, while learning something new, every day.
              </p>
          </div>
         
        </div>




<div className="px-[500px]">
<div className="grid items-center justify-center grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
    
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone"  className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>

  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>

  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  <img src={Image.brandwoman} alt="handphone" className="w-[100%]"/>
  </div>
</div>




        <div className="px-[7%] text-center py-[100px] grid gap-[100px]">
        <div className=" bg-[#00ADEF] border rounded-[40px] flex gap-16 flex-row items-center justify-between gap-4 w-full mt-8 px-12">
            <div className="flex flex-col items-left justify-center flex-wrap w-[50%]   h-[500px] ">
        <p className="text-[#ffffff] text-left font-inter font-bold text-[64px] leading-tight">
        <span className="text-[#F8BC3B]"> Connect</span> to your easy transaction life
        </p>
        <p className="text-[#ffffff] text-left font-inter font-regular text-[18px]">
        We create solutions that enable individuals and communities prosper across Nigeria.
        </p>
       
    </div>
    <div className="mb-8">
            <img src={Image.checkbox} alt="handphone" />

          </div>
          </div>
          
        </div>
        <Footer />
      </main>

    </div>
  );
};

export default Leadership;
