import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";
import { useNavigate } from "react-router-dom";



const Career = () => {
  const navigate= useNavigate()
  return (
    <div>
      <NavBar />
      <main className="inline-block">
      <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] p-12">
      
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Join Paysys
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Become a Part of the Financial Revolution. At PaySys, we’re redefining the future of finance innovative technology and unwavering security. Join us today to elevate your fintech experience and connect with a network that’s leading the industry forward.
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4" onClick={()=>navigate('/contact-us')}>
Contact Us        </button>
    </div>
    <div className="flex-1">
            <img src={Image.career1} alt="handphone" />

          </div>
   
          </div>



          
     

      <div className="flex flex-col items-center justify-center flex-wrap mt-[180px] p-8  ">
        <div className="px-[14%] mb-12 ">
              <h1 className="text-[80px]  font-sans font-black text-left leading-extraTight">
              We believe work is more than a desk or a to-do list. We believe work should empower a better life.
              </h1>
          </div>
        </div>



        <div className="flex flex-row items-center justify-center flex-wrap w-full mt-[100px] p-12">
        <div className="flex-1">
            <img src={Image.career2} alt="handphone" />

          </div>
            <div className="flex-1 flex-col items-left justify-center flex-wrap pr-24">
            <img src={Image.career3} alt="handphone" />
    </div>
   
          </div>

          <div className="flex flex-row items-center justify-center gap-4 w-full mt-8">
           <img src={Image.career4} alt="handphone" />
          </div>

        <div className="px-[7%] text-center py-[100px] grid gap-[100px]">
        <div className=" bg-[#00ADEF] border rounded-[40px] flex gap-16 flex-row items-center justify-between gap-4 w-full mt-8 px-12">
            <div className="flex flex-col items-left justify-center flex-wrap w-[50%]   h-[500px] ">
        <p className="text-[#ffffff] text-left font-inter font-bold text-[64px] leading-tight">
        <span className="text-[#F8BC3B]"> Connect</span> to your easy transaction life
        </p>
        <p className="text-[#ffffff] text-left font-inter font-regular text-[18px]">
        We create solutions that enable individuals and communities prosper across Nigeria.
        </p>
       
    </div>
    <div className="mb-8">
            <img src={Image.checkbox} alt="handphone" />

          </div>
          </div>
          
        </div>
        <Footer />
      </main>

    
    </div>
  );
};

export default Career;
