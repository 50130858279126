import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const PaymentLink = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block ">

        

        <div className="relative p-4 ">
      <div className="absolute inset-0 bg-cover bg-center bg-coin opacity-100"></div>
      <div className="relative flex flex-col items-center justify-center  h-[500px] bg-product4 bg-auto bg-no-repeat bg-center bg-cover mt-[200px]">
          <h1 className="text-largeCard  font-sans font-black text-center leading-extraTight mt-4">
          Send Money to Anyone <br/> or Business In Nigeria
          </h1>
          <p className="text-mediumCard  font-inter font-medium text-center leading-extraTight mt-1">

          Process payouts locally in  30+ states  with Paysys's APIs and software solutions.
          </p>
          <div className=" my-8">
            <div className="h-[60px] w-[120px]">
              <img src={Image.paysyslogo} alt="handphone" />

            </div>
          </div>   <div className="flex gap-4 my-8">
            <button className="border border-textGrey bg-textGrey text-white rounded-full lg:px-16 sm:px-8 py-3">
              Open an account
            </button>
            <button className="border  border-textGrey bg-transparent text-extGrey rounded-full lg:px-16 sm:px-8 py-3">
          Watch demo
            </button>
          </div>

        </div>
        </div>

          <div className="flex lg:flex-row sm:flex-col items-center justify-center flex-wrap w-full mt-[100px] lg:p-12 p-4">
            <div className="flex-1 flex-col items-left justify-center flex-wrap lg:pr-24">
        
        <p className="text-large sm:text-[40px]  font-sans font-black text-left leading-extraTight">
        Built for and loved by developers
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Easily build payment flows for any use case — from online collections to payouts and everything in between — by integrating our SDKs and API endpoints.
        </p>
       
    </div>
    <div className="flex-1">
          

          </div>
          </div>
      
          <div className="flex gap-4 items-center lg:px-[8%]  lg:flex-row  sm:flex-col sm:justify-center  lg:justify-between  max-w-full sm:px-[10px] p-4">
              <div className="flex-1 bg-[#F7F6F9] rounded-[30px] p-8">
              <img
                  src={Image.bill1}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Paysys
                  </h1>
                 <p>Leverage our Technology Expertise to Grow Your Business</p>
                  </div>
                
                  {/* <button className="border border-textGrey bg-textGrey text-white rounded-full px-4 py-2">
            <img src={Image.arrowright} alt="people" className="m-auto h-8 w-8" />
            </button> */}
                </div>
                
              </div>
              <div className="flex-1 bg-[#F7F6F9] rounded-[30px] p-8 mt-[100px]">
              <img
                  src={Image.bill2}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Online Banking
                  </h1>
                 <p>Make Everyday Payments with Ease</p>
                  </div>
                
                  {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
                </div>
                
              </div>
              <div className=" flex-1 bg-[#F7F6F9] rounded-[30px] p-8 mt-[200px]">
              <img
                  src={Image.bill3}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
                Cards
                  </h1>
                 <p>Explore our Suite ofCards & Digital Tokens</p>
                  </div>
                
                 
                  {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
                </div>
                
              </div>
        </div> 
      
        <div className="flex gap-4 items-center lg:px-[8%]  lg:flex-row  sm:flex-col sm:justify-center  lg:justify-between  max-w-full sm:px-[10px] p-4">
        <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8">
        <img
                  src={Image.bill4}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
             Online Store
                  </h1>
                 <p>Integrate DigitalPayment and Get anOnline Store</p>
                  </div>
                
                
                  {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
                </div>
                
              </div>
              <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8 mt-[100px]">
              <img
                  src={Image.bill5}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
                 QR Code
                  </h1>
                 <p>Make Everyday Payments with Ease</p>
                  </div>
                
                 
                  {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
                </div>
                
              </div>
              <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8 mt-[200px]">
              <img
                  src={Image.bill6}
                  alt="woman working"
                  className="object-cover max-w-full"
                />
                <div className="p-[16px] flex items-end justify-between gap-8 ">
                  <div>
                  <h1 className="font-semibold text-[40px] text-[#2A496B]">
          Payment Gateway
                  </h1>
                 <p>process payments online</p>
                  </div>
                
                
               
                  {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
                </div>
                
              </div>
        </div> 
    
        <div className="lg:px-[7%] text-center lg:py-[100px] grid gap-[100px] sm:px-[10px] p-4">
        <div className=" bg-[#00ADEF] border rounded-[40px] flex  gap-16 lg:flex-row sm:flex-col items-center justify-between gap-4 max-w-full mt-8 lg:px-12">
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%]   h-[500px] ">
        <p className="text-[#ffffff] text-left font-inter font-bold text-[64px] leading-tight">
        <span className="text-[#F8BC3B]"> Connect</span> to your easy transaction life
        </p>
        <p className="text-[#ffffff] text-left font-inter font-regular text-[18px]">
        We create solutions that enable individuals and communities prosper across Nigeria.
        </p>
       
    </div>
    <div className="mb-8">
            <img src={Image.checkbox} alt="handphone" />

          </div>
          </div>
          
        </div>

      <Footer /> 
      </main>
    </div>
  );
};

export default PaymentLink;
