
import handphone from './handphone.svg'
import brand from './brand.svg'
import brandwoman from './brandwoman.svg'
import paysyslogo from './paysyslogo.svg'
import sendmoney from './sendmoney.svg'
import partners from './partners.svg'
import pos1 from './pos-section.svg'
import map from './map.svg'
import atmcard from './atmcard.svg'
import features from './features.png'
import recievemoney from './recievemoney.svg'
import partner1 from './partner1.svg'
import partner2 from './partner2.svg'
import partner3 from './partner3.svg'
import partner4 from './partner4.svg'
import partner5 from './partner5.svg'
import partner6 from './partner6.svg'
import partner7 from './partner7.svg'
import partner8 from './partner8.svg'
import partner9 from './partner9.svg'
import partner10 from './partner10.svg'
import solutionright from './solutionright.svg'
import solutionleft from './solutionleft.svg'
import laptop from './laptop.svg'
import girl from './girl.svg'
import key from './key.svg'
import money from './money.svg'
import personalstore from './personalstore.svg'
import invoice from './invoice.svg'
import checkbox from './checkbox.svg'
import paysysbanking from './paysysbanking.svg'
import phone from './phone.svg'
import payment1 from './payment1.svg'
import payment2 from './payment2.svg'
import payment3 from './payment3.svg'
import payment4 from './payment4.svg'
import arrowright from './arrowright.svg'
import bill1 from './bill1.svg'
import bill2 from './bill2.svg'
import bill3 from './bill3.svg'
import bill4 from './bill4.svg'
import bill5 from './bill5.svg'
import bill6 from './bill6.svg'
import vector from './Vector.svg'
import product5 from './product5.svg'
import mobilewallet from './mobilewallet.svg'
import agency from './agency.svg'

import paymentlink from './paymentlink.svg'
import virtualterminal from './virtualterminal.svg'
import onlinecheckout from './onlinecheckout.svg'
import switchingpayment from './switchingpayment.svg'

import brand1 from './brand1.svg'
import brand2 from './brand2.svg'
import brand3 from './brand3.svg'
import brand4 from './brand4.png'


import career1 from './careers1.svg'
import career2 from './careers2.svg'
import career3 from './careers3.svg'
import career4 from './careers4.svg'



export const Image={
  paysyslogo,
    handphone,
    brand,
    brandwoman,
    sendmoney,
    partners,
    pos1,
    atmcard,
    map,
    features,
    recievemoney,
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    solutionleft,
    solutionright,
    laptop,
    girl,
    key,
    money,
    personalstore,
    invoice,
    checkbox,
    paysysbanking,
    phone,
    payment1,
    payment2,
    payment3,
    payment4,
    arrowright,
    bill1,
    bill2,
    bill3,
    bill4,
    bill5,
    bill6,
    vector,
    product5,
    mobilewallet,
    agency,
    virtualterminal,
    onlinecheckout,
    paymentlink,
    switchingpayment,
    brand1,
    brand2,
    brand3,
    brand4,
    career1,
    career2,
    career3,
    career4
  }
  

export const SpinnerIcon = ({prop}: any):any => {
    return(
        <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
      </svg>
    )
 }