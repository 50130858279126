import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";



const Products = () => {

  return (
    <div>
      <NavBar />

      <main className="inline-block">

      <div className="flex flex-col items-center justify-center flex-wrap mt-12 lg:p-12">
          <div className="flex flex-row items-left justify-center  w-full mt-12">
            <div className="flex flex-col items-left justify-center flex-wrap pr-24">
        
        <p className="text-large  font-sans font-black text-left leading-extraTight">
        Paysys Payment <br/> Gateway
        </p>
        <p className="text-[#838383] text-left font-regular text-medium">
        Welcome to Paysys Mobile Wallet, <br/> your one-stop solution for seamless, secure, <br/> and convenient digital transactions
        </p>
        <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
        Learn more
        </button>
    </div>
    <div className="">
            <img src={Image.vector} alt="handphone" />

          </div>
          </div>
        </div>  


        <div className="flex flex-col items-center justify-center  h-[500px]"  style={{ backgroundImage: "url('../assets/features.png')" }}>
          <h1 className="text-largeCard  font-sans font-black text-center leading-extraTight">
            Simple, easy payments
          </h1>
          <p className="text-mediumCard  font-inter font-medium text-center leading-extraTight mt-1">

            Building a business is hard. Getting paid shouldn't be with
          </p>
          <div className=" my-8">
            <div className="h-[60px] w-[120px]">
              <img src={Image.paysyslogo} alt="handphone" />

            </div>
          </div>
        </div>


        <div className="flex flex-col items-center justify-center flex-wrap mt-12 lg:p-12">
      <div className="flex flex-row items-left justify-center  w-full mt-12">
      <div className="pl-12">
        <img src={Image.vector} alt="handphone" />
      </div>
     
        <div className="flex flex-col items-left justify-center flex-wrap pl-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight">
    Paysys Agency <br/>  Banking Services
    </p>
    <p className="text-[#838383] text-left font-regular text-medium">
    Welcome to Paysys Agency Banking Services, the cutting-edge solution designed to extend banking services to underserved and unbanked populations
    </p>
    <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button>
        </div>

      </div>
    </div>  



    <div className="flex flex-col items-center justify-center flex-wrap mt-12 lg:SSSp-12">
      <div className="flex flex-row items-left justify-center  w-full mt-12">
     
        <div className="flex flex-col items-left justify-center flex-wrap pr-24">
    
    <p className="text-large  font-sans font-black text-left leading-extraTight">
    Paysys Payment <br/> Gateway
    </p>
    <p className="text-[#838383] text-left font-regular text-medium">
    Welcome to Paysys Mobile Wallet, <br/> your one-stop solution for seamless, secure, <br/> and convenient digital transactions
    </p>
    <button className="bg-transparent  border border-black rounded-full  text-textGrey w-[160px] rounded-full px-4 py-3 mt-4">
Learn more
    </button>
</div>
<div className="">
        <img src={Image.vector} alt="handphone" />

      </div>
      </div>
    </div>  
    <Footer />
      </main>

 
    </div>
  );
};

export default Products;
