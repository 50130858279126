import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar"
import Footer from "../components/reuseables/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const images = [
  "url('../assets/bg-hero.svg')",
  "url('../assets/ladycomputer.svg')",
  "url('../assets/bg-hero.svg')",
  "url('../assets/ladycomputer.svg')",
  "url('../assets/bg-hero.svg')",
];


const LandingPage = () => {
  const navigate = useNavigate()
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);


  const texts = ["Get payment for all transaction with one click", "Make all your transaction with us within a minute", "Fast, secure, and effortless payments at your fingertips"];

  // State to keep track of the current text index
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // Change the text every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [texts.length]);

  return (
    <div>
      <NavBar />

      <main className="inline-block">



        <div className="relative ">
          <div className="absolute inset-0 bg-cover bg-center bg-hero opacity-10"></div>
          <div className="relative flex flex-col items-center  flex-wrap lg:px-[10%] lg:pt-[160px] sm:pt-[100px] pb-4 ">
            <div className="flex my items-center gap-2  bg-[#F3F4F5] rounded-full lg:px-2.5 py-1 sm:mb-2">
              <button className="flex my items-center gap-2 bg-textGrey rounded-full lg:px-3 py-1 px-1">
                <p className="text-[0.7rem] sm:text-extraSmall text-nowrap  text-white font-circular"> New</p>
              </button>
              <p className="text-[0.7rem] sm:text-extraSmall text-nowrap  text-green font-circular">
                New Automations is now live!
              </p>
              <p>🎉</p> <RiArrowRightSLine />
            </div>
            <h1 className="text-large  fade-in-text font-sans font-black text-center leading-extraTight">
              {texts[currentTextIndex]}

            </h1>
            <p className="text-medium font-inter font-medium text-center leading-extraTight lg:px-24 pt-1 pb-4">




              Empowering your transactions with innovative, secure and streamlined
              payment a and collection solution
            </p>
            <div className="flex gap-4 my-8">

              <button className="border border-textGrey bg-textGrey text-white rounded-full lg:px-16 sm:px-8 py-3">
                Create account
              </button>
              <button className="border  border-textGrey bg-transparent text-extGrey rounded-full  lg:px-16 sm:px-8 py-3" onClick={() => navigate('/about')}>
                Learn more
              </button>
            </div>
          </div>
          <div className=" relative flex  h-[800px] lg:w-[800px]">
            <img src={Image.handphone} alt="handphone" />
          </div>
          <div className="max-w-full h-fit mt-[-500px]">
            <img src={Image.partners} alt="handphone" />

          </div>
        </div>


        <div className="flex lg:flex-row md:flex-row sm:flex-col sm:items-center sm:justify-center sm:gap-8  lg:justify-between max-w-full lg:px-[10%] py-16 sm:my-24 ">


          <div className="px-[10px] ">
            <h1 className="text-large  font-sans font-black text-left leading-extraTight pt-8 sm:pt-24 sm:px-[10px] sm:items-center sm:text-center">
              Paysys for <br />Global Brands
            </h1>

            <p className="text-medium  font-inter font-medium text-left leading-extraTight my-4 sm:px-[10px] sm:items-center sm:text-center">
              We provide financial technology services, <br /> specializing in payment solutions across Africa


            </p>
            <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
              <button className="sm:flex sm:flex-cols sm:items-center sm:justify-center bg-[#00ADEF] text-white rounded-full px-16 py-2.5  sm:items-center sm:text-center" onClick={() => navigate('/enterprise-solution')}>
                Start free
              </button>
            </div>

          </div>

          <div className="h-[200px] w-[200px] ">
            <img src={Image.brand} alt="handphone" />

          </div>

        </div>




        <div className="relative max-w-full h-full ">
          <div className="absolute inset-0 bg-cover bg-center bg-ladycomputer opacity-60"></div>
          <div className="relative flex flex-col items-center justify-center  h-[500px]" >

            <h1 className="text-largeCard  font-sans font-black text-center leading-extraTight">
              Simple and Easy Payments
            </h1>
            <p className="text-[24px]  font-inter font-medium text-center leading-extraTight mt-1">

              Building a business is hard. Getting paid shouldn't be
            </p>
            <div className=" my-8">
              <div className="h-[60px] w-[120px]">
                <img src={Image.paysyslogo} alt="handphone" />

              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-16 p-2 max-w-full mb-24 ">
          <div className="flex lg:flex-row sm:flex-col items-center sm:justify-center justify-between flex-wrap max-w-full">
            <div className="flex-1 w-[100%]">
              <img src={Image.sendmoney} alt="handphone" className="w-[100%]" />
            </div>
            <div className="flex-1 flex-row items-center justify-center flex-wrap lg:h-[450px] rounded-[30px] h-full hover:bg-[#00ADEF]  sm:w-[100%]  sm:mt-2">
              <h1 className="flex flex-row items-center justify-center text-largeCard  font-sans font-thin  leading-extraTight  mt-[10%] sm:mb-16">
                Send <br /> Money  <br /> Anywhere
              </h1>
            </div>
          </div>
          <div className="flex lg:flex-row sm:flex-col items-center sm:justify-center justify-between flex-wrap max-w-full mt-[2rem]">
            <div className="flex-1 lg:h-[450px] flex-cols items-center justify-center flex-wrap  rounded-[30px]  h-full hover:bg-[#F68D27] sm:w-[100%] sm:mb-2 ">
              <h1 className="flex flex-row items-center justify-center text-largeCard  font-sans font-black  leading-extraTight mt-[10%] sm:mb-16">
                Recieve <br /> Money  <br /> Anywhere
              </h1>
            </div>
            <div className="flex-1 w-[100%]">
              <img src={Image.recievemoney} alt="handphone" className="w-[100%]" />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center flex-wrap mt-[40px] lg:p-12  bg-[#E3ECFF]  sm:px-[10px]">
          <div className="lg:px-[14%] sm:px-[20px] mb-12">
            <h1 className="text-large  font-sans font-black lg:text-left sm:text-center leading-extraTight mt-16">
              Unique solutions  for payments and collections
            </h1>
            <p className="text-medium font-inter font-medium lg:text-left sm:text-center leading-extraTight">
              Tailored to Meet Your Financial Needs with Precision and Efficiency.
            </p>
          </div>



          <div className="flex lg:flex-row  md: flex-col sm:flex-col sm:justify-center items-center lg:justify-between gap-4 max-w-full mt-12">
            <div className="relative lg:w-[50%] ">
              <div className="absolute inset-0 bg-cover bg-center bg-business1 opacity-10"></div>
              <div className="flex flex-col lg:items-left sm:items-center  justify-center flex-wrap lg:w-[100%] sm:w-[100%]  bg-[#fff] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[80px] sm:px-[20px]">

                <p className="text-[#011F44] lg:text-left sm:text-center font-bold lg:text-[60px] sm:text-[36px] leading-tight">
                  Paysys Payment Gateway
                </p>
                <p className="text-[#6B5A74] lg:text-left sm:text-center font-semibold lg:text-[22px] text-[16px] my-2">
                  Your gateway to seamless, secure, <br />  and innovative payment solutions.
                </p>
                <button className=" text-center border border-textGrey bg-transparent text-[#6B5A74] w-[150px] rounded-full px-4 py-2 mt-4" onClick={() => navigate('/enterprise-solution#payment-gateway')}>
                  Learn more
                </button>
              </div>
            </div>

            <div className="relative lg:w-[50%]">
              <div className="absolute inset-0 bg-cover bg-center bg-business3 opacity-10"></div>
              <div className="flex flex-col lg:items-left sm:items-center justify-center flex-wrap lg:w-[100%] sm:w-[100%]  bg-[#FFF] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[80px] sm:px-[20px]">

                <p className="text-[#011F44] lg:text-left sm:text-center font-bold lg:text-[60px] sm:text-[36px] leading-tight">
                  Paysys Agency Banking Services
                </p>
                <p className="text-[#6B5A74] lg:text-left sm:text-center font-semibold lg:text-[22px] text-[16px] mt-2">
                  From startup to scale-up, we can support you at every stage of your businesses’ growth
                </p>
                <button className="  text-center border border-textGrey bg-transparent text-[#6B5A74] w-[150px] rounded-full px-4 py-2 mt-4" onClick={() => navigate('/enterprise-solution#agency-banking')}>
                  Learn more
                </button>
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row  md: flex-col sm:flex-col sm:justify-center items-center lg:justify-between gap-4 max-w-full mt-12">
            <div className="relative lg:w-[50%] ">
              <div className="absolute inset-0 bg-cover bg-center bg-business2 opacity-10"></div>
              <div className="relative flex flex-col lg:items-left sm:items-center justify-center flex-wrap lg:w-[100%] sm:w-[100%]  bg-[#FFF] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[80px] sm:px-[20px]">

                <p className="text-[#011F44] lg:text-left sm:text-center font-bold lg:text-[60px] sm:text-[36px] leading-tight">
                  Paysys Point-of-Sale(POS)
                </p>
                <p className="text-[#6B5A74] lg:text-left sm:text-center font-semibold lg:text-[22px] text-[16px] mt-2">
                  We empower businesses of all sizes to elevate their customer service and operational efficiency.
                </p>
                <button className="  text-center border border-textGrey bg-transparent text-[#6B5A74] w-[150px] rounded-full px-4 py-2 mt-4" onClick={() => navigate('/enterprise-solution#point-of-sale')}>
                  Learn more
                </button>
              </div></div>
            <div className="relative lg:w-[50%] ">
              <div className="absolute inset-0 bg-cover bg-center bg-business4 opacity-10"></div>
              <div className="relative flex flex-col  lg:items-left sm:items-center justify-center flex-wrap lg:w-[100%] sm:w-[100%]  bg-[#FFF] bg-opacity-50 h-[500px] border rounded-[35px] lg:px-[80px] sm:px-[20px]">

                <p className="text-[#011F44] lg:text-left sm:text-center font-bold lg:text-[60px] sm:text-[36px] leading-tight">
                  Paysys Mobile <br /> Wallet
                </p>
                <p className="text-[#6B5A74] lg:text-left sm:text-center font-semibold lg:text-[22px] text-[16px] mt-2">
                  We help global brands accept payments from across Africa
                </p>
                <button className=" text-center border border-textGrey bg-transparent text-[#6B5A74] w-[150px] rounded-full px-4 py-2 mt-4" onClick={() => navigate('/enterprise-solution#mobile-wallet')}>
                  Learn more
                </button>
              </div>  </div>
          </div>

          <div className="flex flex-row items-center justify-between gap-4 max-w-full mt-8">

          </div>


        </div>

        <div className="relative max-w-full h-full ">
          <div className="absolute inset-0 bg-cover bg-center bg-who-we-are"></div>
          <div className="relative flex flex-col items-left justify-center flex-wrap h-[500px] lg:px-[30%]">
            <h1 className="text-large   font-sans font-black  leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
              WHO WE ARE
            </h1>
            <p className="text-medium  font-inter font-medium  leading-extraTight my-4 sm:px-[10px] sm:items-center sm:text-center">
              We offer endless enterprise and payment solutions
            </p>
            <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
              <button className="bg-[#00ADEF] text-center border border-textGrey bg-textGrey text-white rounded-full mt-4 w-[160px] px-4 py-2 sm:items-center sm:text-center" onClick={() => navigate('/about')}>
                Read more
              </button></div>
          </div>
        </div>

        <div className="flex flex-col items-left justify-center flex-wrap py-8 lg:px-[10%] ">

          <h1 className="text-[76px] sm:text-[36px]  font-inter font-black leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
            Almost any bill payment is possible on our digital payment platform, Paysys.
          </h1>

        </div>

        <div className="flex gap-4 items-center lg:px-[8%] sm:px-[10px]  lg:flex-row  sm:flex-col sm:justify-center  lg:justify-between  max-w-full  ">
          <div className="flex-1 bg-[#F7F6F9] rounded-[30px] p-8">
            <img
              src={Image.bill1}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Paysys
                </h1>
                <p>Leverage our Technology Expertise to Grow Your Business</p>
              </div>

              {/* <button className="border border-textGrey bg-textGrey text-white rounded-full px-4 py-2">
            <img src={Image.arrowright} alt="people" className="m-auto h-8 w-8" />
            </button> */}
            </div>

          </div>
          <div className="flex-1 bg-[#F7F6F9] rounded-[30px] p-8 lg:mt-[100px]">
            <img
              src={Image.bill2}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Online Banking
                </h1>
                <p>Make Everyday Payments with Ease</p>
              </div>

              {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
            </div>

          </div>
          <div className=" flex-1 bg-[#F7F6F9] rounded-[30px] p-8 lg:mt-[200px]">
            <img
              src={Image.bill3}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Cards
                </h1>
                <p>Explore our Suite ofCards & Digital Tokens</p>
              </div>


              {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
            </div>

          </div>
        </div>

        <div className="flex gap-4 items-center lg:px-[8%] sm:px-[10px]  lg:flex-row  sm:flex-col sm:justify-center  lg:justify-between  max-w-full ">
          <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8">
            <img
              src={Image.bill4}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Online Store
                </h1>
                <p>Integrate DigitalPayment and Get anOnline Store</p>
              </div>


              {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
            </div>

          </div>
          <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8 lg:mt-[100px]">
            <img
              src={Image.bill5}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  QR Code
                </h1>
                <p>Make Everyday Payments with Ease</p>
              </div>


              {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
            </div>

          </div>
          <div className="flex-1 bg-[#D0CDD5]  rounded-[30px] p-8 lg:mt-[200px]">
            <img
              src={Image.bill6}
              alt="woman working"
              className="object-cover max-w-full"
            />
            <div className="p-[16px] flex items-end justify-between gap-8 ">
              <div>
                <h1 className="font-semibold text-[40px] text-[#2A496B]">
                  Payment Gateway
                </h1>
                <p>process payments online</p>
              </div>



              {/* <button className="border border-textGrey bg-textGrey text-white lg:px-[20px] py-[18px] rounded-full flex items-center justify-center text-[16px]  w-fit mt-[20px]">
                
                  <img src={Image.arrowright} alt="people" className="m-auto h-[16px] w-[16px]" />
                  </button> */}
            </div>

          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-wrap py-[100px] ">

          <h1 className="text-[26px]  text-[#F8BC3B] font-inter font-bold text-center leading-normal">
            Powering growth for amazing <br /> businesses
          </h1>
          <p className="text-[18px]  text-[#57585A] font-inter font-regular text-center leading-normal mt-4">
            Paysys is a growth engine for a new generation of innovative, forward-looking <br />
            organizations operating in Africa
          </p>

        </div>
        <div className="  grid grid-cols-4  md:grid-cols-none md:grid-flow-col w-[90%] mx-auto  ">
          <img src={Image.partner1} alt="microsoft-logo" className="w-[150px] " />
          <img src={Image.partner2} alt="aws-logo" className="w-[150px] mt-16" />
          <img src={Image.partner3} alt="google-logo" className="w-[150px] " />
          <img src={Image.partner4} alt="fujitsu-logo" className="w-[150px] mt-16" />
          <img src={Image.partner5} alt="EY-logo" className="w-[60px]" />
          <img src={Image.partner6} alt="deliotte-logo" className="w-[60px] mt-16" />
          <img src={Image.partner7} alt="Radobank-logo" className="w-[150px] " />
          <img src={Image.partner8} alt="Netherland-logo" className="w-[150px] mt-16" />
          <img src={Image.partner9} alt="Netherland-logo" className="w-[150px] " />
          <img src={Image.partner10} alt="Netherland-logo" className="w-[150px] mt-16" />
        </div>

        <div className="max-w-full h-fit ">
          <img src={Image.partners} alt="handphone" />

        </div>

        <div className="relative max-w-full h-full mt-[-100px] ">
          <div className="absolute inset-0 bg-cover bg-center bg-pos-section"></div>
          <div className=" relative flex flex-col items-center justify-center flex-wrap   ">

            <div className="flex flex-row items-start justify-start flex-wrap lg:px-[30%] max-w-full py-8 sm:px-[10px] sm:items-center sm:text-center">
              <div> <h1 className="text-largeCard  font-sans font-black  leading-extraTight mt-8">
                Superior Devices
              </h1>
                <p className="text-mediumCard font-inter font-medium leading-extraTight mt-8 mb-4 sm:px-[10px] sm:items-center sm:text-center">
                  Imagine a world where technology seamlessly integrates into every aspect of your day. Superior devices are not just tools; they're companions in efficiency, reliability, and innovation. From sleek, powerful pos devices that anticipate your needs to cutting-edge features that empower your creativity, our pos devices redefine what's possible.
                </p>
                <button className="border border-textGrey bg-textGrey text-white rounded-full mt-4 w-[180px] px-4 py-2 sm:px-[10px] sm:items-center sm:text-center">
                  Create account
                </button>
              </div>
              <div className="">
                <img src={Image.pos1} alt="handphone" className="h-full  w-[100%]" />

              </div>
            </div>
            <div className="flex flex-row items-start justify-start flex-wrap  lg:pl-[30%] lg:pr-[15%] max-w-full mt-[6rem] mb-8 sm:items-center sm:text-center">
              <h1 className="text-largeCard  font-sans font-black  leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
                Card Transactions and Processing
              </h1>
              <p className="text-mediumCard  font-inter font-medium  leading-extraTight my-4 sm:px-[10px] sm:items-center sm:text-center">
                Imagine stepping into a world where convenience meets security at every payment. Our Card transactions effortlessly bridge the gap between consumers and merchants, enabling purchases that are swift, secure, and reliable. They empower businesses large and small, from bustling urban storefronts to quaint online boutiques, to cater to customers with ease.  </p>
              <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">  <button className="border border-textGrey bg-textGrey text-white rounded-full mt-4 w-[180px] px-4 py-2 sm:items-center sm:text-center">
                Create account
              </button></div>
            </div>
            <div className=" w-[100%] mt-8">
              <img src={Image.atmcard} alt="handphone" className=" w-[100%]" />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-wrap mt-12 lg:p-12 ">
          <div className="lg:px-[14%]">
            <h1 className="text-large  font-inter font-black text-center leading-extraTight">
              We offer endless enterprise solution
            </h1>

          </div>



          <div className="flex flex-row items-center justify-center  max-w-full mt-12 gap-4 lg:flex-row sm:flex-col sm:justify-center gap-8">
            <div className="">
              <img src={Image.solutionleft} alt="handphone" />

            </div>
            <div className="flex flex-col items-left justify-center flex-wrap lg:pl-24">

              <p className="text-[#000000] text-left font-semibold text-[40px] sm:px-[10px] sm:items-center sm:text-center">
                What we offer!
              </p>
              <p className="text-[#838383] text-left font-regular text-[20px] sm:px-[10px] sm:items-center sm:text-center">
                Paysys provides advanced features that enable business<br />
                to get all the benefits of chat management
              </p>
              <p className="font-medium text-[24px] text-[#000000] mt-4 sm:px-[10px] sm:items-center sm:text-center">Accept online payment</p>
              <p className="font-medium text-[24px] text-[#000000] mt-4 sm:px-[10px] sm:items-center sm:text-center">Pos system</p>
              <p className="font-medium text-[24px] text-[#000000] mt-4 sm:px-[10px] sm:items-center sm:text-center">Withdraw</p>
              <p className="font-medium text-[24px] text-[#000000] mt-4 sm:px-[10px] sm:items-center sm:text-center">Payment Links</p>
            </div>
          </div>


          <div className="flex flex-row items-center justify-center  max-w-full mt-12 gap-4 lg:flex-row sm:flex-col sm:justify-center gap-8">

            <div className="flex flex-col items-left justify-center flex-wrap lg:pr-24">

              <p className="text-[#000000] text-left font-semibold text-[40px] sm:px-[10px] sm:items-center sm:text-center">
                Contact us here
              </p>
              <p className="text-[#838383] text-left font-regular text-[20px] sm:px-[10px] sm:items-center sm:text-center">
                Seamlessly integerate our chat management solution with<br /> your
                other tools and services for a streamlined transactions
              </p>
              <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
                <button className="bg-[#00ADEF] text-[#ffffff] w-[160px] rounded-full px-4 py-2.5 mt-4  sm:items-center sm:text-center" onClick={() => navigate('/contact-us')} >
                  Contact Us
                </button></div>

            </div>
            <div className="">
              <img src={Image.solutionright} alt="handphone" />

            </div>
          </div>




        </div>

        <div className="relative max-w-full h-full ">
          <div className="absolute inset-0 bg-cover bg-center bg-offers"></div>
          <div className=" relative flex flex-col items-left justify-center flex-wrap h-[600px]  sm:pr-[5%]  lg:pr-[30%] lg:pl-[40%]" >
            <h1 className="lg:text-[60px]  sm:text-[38px] font-sans font-regular text-[#333333]  leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
              Paysys is for everyone who pays online.
            </h1>
            <h1 className="lg:text-[24px] sm:text-[18px] font-inter font-regular text-[#333333]  leading-extraTight lg:mt-12 sm:mt-4 sm:px-[10px] sm:items-center sm:text-center">
              Transact anywhere online
            </h1>
            <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full"><button className="bg-[#F5811E]  text-white rounded-full mt-4 w-[150px] px-4 py-2.5 sm:items-center sm:text-center" onClick={() => navigate('/payment-solution')}>
              Learn more
            </button></div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-wrap p-12 sm:p-1 bg-[#BACEDC] sm:py-16 ">
          <div className="lg:px-[20px] mb-12">
            <h1 className="text-[80px] sm:text-[36px]  font-sans font-black text-left leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
              Pushing the boundaries of innovation to deliver payment solutions across Nigeria
            </h1>
            <p className="text-[36px]  sm:text-[24px] font-inter font-regular text-left leading-tight mt-2 sm:px-[10px] sm:items-center sm:text-center">
              Bespoke payment solutions for your modern lifestyle, business collections, disbursements and payment processing
            </p>
          </div>

          <div className="flex lg:flex-row sm:flex-col  items-center justify-between gap-4 max-w-full mt-8 lg:px-[130px] sm:px-[10px]">
            <div className="flex flex-col gap-4 items-center justify-center flex-wrap  sm:w-[100%] w-[50%]  bg-[#E4E7FF]  lg:h-[400px] sm:py-4 rounded-[35px] lg:px-[100px]">
              <div className="">
                <img src={Image.laptop} alt="handphone" />

              </div>

              <p className="text-[#000000] text-center font-regular lg:text-[26px] sm:text-[20px] sm:px-4">
                Billers aggregated on
                the Paysys platform
              </p>

            </div>
            <div className="flex flex-col gap-4 items-center justify-center flex-wrap w-[50%] sm:w-[100%]  bg-[#E4E7FF]  lg:h-[400px] sm:py-4 rounded-[35px] sm:px-[30px] lg:px-[100px] ">
              <div className="">
                <img src={Image.girl} alt="handphone" />

              </div>

              <p className="text-[#000000] text-center font-regular lg:text-[26px] sm:text-[20px] sm:px-4">
                Billers cards activated on the Verve network aggregated on
                the Paysys platform
              </p>

            </div>
          </div>



          <div className="flex  items-center justify-between gap-4 max-w-full lg:mt-8 lg:flex-row w-[100%] lg:px-[120px] sm:flex-col sm:py-4 sm:justify-center max-w-full  sm:px-[10px]">
            <div className="flex flex-col gap-4 items-center justify-center flex-wrap w-[100%]  bg-[#FAF1E3]  h-[400px] sm:py-4 rounded-[35px] lg:px-[100px]">
              <div className="">
                <img src={Image.laptop} alt="handphone" />

              </div>

              <p className="text-[#000000] text-center font-regular lg:text-[26px] sm:text-[20px] sm:px-4">
                Billers aggregated on<br />
                the Paysys platform
              </p>

            </div>

          </div>


          <div className="flex lg:flex-row sm:flex-col  items-center justify-between gap-4 max-w-full lg:mt-8 lg:px-[130px]  sm:px-[10px]">
            <div className="flex flex-col gap-4 items-center justify-center flex-wrap  sm:w-[100%] w-[50%]  bg-[#E4E7FF]  lg:h-[400px] sm:py-4 rounded-[35px] lg:px-[100px]">
              <div className="">
                <img src={Image.key} alt="handphone" className="sm:px-[20px]"/>

              </div>

              <p className="text-[#000000] text-center font-regular lg:text-[26px] sm:text-[20px] sm:px-4">
                Quickteller Paypoint agents enabled across Nigeria
              </p>

            </div>
            <div className="flex flex-col gap-4 items-center justify-center flex-wrap sm:w-[100%] w-[50%]  bg-[#E4E7FF] h-[400px]  rounded-[35px] lg:px-[100px]">
              <div className="">
                <img src={Image.money} alt="handphone" />

              </div>

              <p className="text-[#000000] text-center font-regular lg:text-[26px] sm:text-[20px] sm:px-4 ">
                Active businesses transacting daily
              </p>

            </div>
          </div>

        </div>

        <div className="relative max-w-full h-full ">
          <div className="absolute inset-0 bg-cover bg-center bg-pos-section"></div>
          <div className="relative flex flex-col items-center justify-center flex-wrap mt-12 lg:p-8">
            <div className="lg:px-[100px] mb-12">
              <h1 className="text-large  font-sans font-black text-left leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
                Endless payment possibilities for every small business.
              </h1>
              <p className="text-[#57585A] font-inter font-medium text-left text-[24px] leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
                Building a business is hard. Getting paid shouldn't be with
              </p>
            </div>

            <div className="flex lg:gap-16 sm:gap-8 lg:flex-row sm:flex-col items-center justify-between  max-w-full mt-8 lg:px-12">
              <div className="sm:mx-[10px]">
                <img src={Image.invoice} alt="handphone" />

              </div>
              <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%] sm:mb-4 lg:h-[500px] ">

                <p className="text-[#57585A] text-left font-inter font-bold text-[76px] sm:text-[36px] sm:px-[10px] sm:items-center sm:text-center">
                  Personal stores
                </p>
                <p className="text-[#6B5A74] text-left font-inter font-regular lg:text-[26px] sm:text-[20px] sm:px-[10px] sm:items-center sm:text-center">
                  Create a free ecommerce website and start selling worldwide with just a few clicks
                </p>
                <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
                  <button className="  border border-textGrey bg-transparent text-[#6B5A74] w-[120px] rounded-full px-4 py-2 mt-4 sm:items-center sm:text-center" onClick={() => navigate('/enterprise-solution')}>
                    Learn more
                  </button></div>
              </div>
            </div>

            <div className="flex lg:gap-16 sm:gap-8 lg:flex-row sm:flex-col items-center justify-between  max-w-full mt-8 lg:px-12">
              <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%]   lg:h-[500px]  ">

                <p className="text-[#57585A] text-left font-inter font-bold text-[76px] sm:text-[36px] sm:px-[10px] sm:items-center sm:text-center">
                  Invoices
                </p>
                <p className="text-[#6B5A74] text-left font-inter font-regular lg:text-[26px] sm:text-[20px] sm:px-[10px] sm:items-center sm:text-center">
                  Generate professional invoices for your customers and get paid from anywhere.
                </p>
                <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
                  <button className="  border border-textGrey bg-transparent text-[#6B5A74] w-[120px] rounded-full px-4 py-2 mt-4 sm:items-center sm:text-center" onClick={() => navigate('/enterprise-solution')}>
                    Learn more
                  </button></div>
              </div>
              <div className="sm:mx-[10px]">
                <img src={Image.personalstore} alt="handphone" />

              </div>
            </div>

            <div className="flex lg:gap-16 sm:gap-8 lg:flex-row sm:flex-col items-center justify-between  max-w-full mt-8 lg:px-12">
              <div className="sm:mx-[10px]">
                <img src={Image.invoice} alt="handphone" />

              </div>
              <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%]   lg:h-[500px] ">

                <p className="text-[#57585A] text-left font-inter font-bold text-[76px] sm:text-[36px] sm:px-[10px] sm:items-center sm:text-center">
                  Payment links
                </p>
                <p className="text-[#6B5A74] text-left font-inter font-regular lg:text-[26px] sm:text-[20px] sm:px-[10px] sm:items-center sm:text-center">
                  Receive one-off or recurring payments from anyone, anywhere, via your unique payment link
                </p>
                <div className="sm:flex sm:flex-cols sm:items-center sm:justify-center sm:w-full">
                  <button className="  border border-textGrey bg-transparent text-[#6B5A74] w-[120px] rounded-full px-4 py-2 mt-4 sm:items-center sm:text-center" onClick={() => navigate('/enterprise-solution')}>
                    Learn more
                  </button></div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative max-w-full h-full">
          <div className="absolute inset-0 bg-cover bg-center bg-pos-section"></div>
          <div className="relative flex flex-col items-center justify-center flex-wrap mt-12 lg:p-16">
            <div className="lg:px-[100px] mb-16">
              <h1 className="text-large  font-sans font-black text-left leading-extraTight sm:px-[10px] sm:items-center sm:text-center">
                Endless payment possibilities for every Small Business.
              </h1>
              <p className="text-[24px] font-inter font-medium text-left leading-extraTight mt-1 sm:px-[10px] sm:items-center sm:text-center">
                Building a business is hard. Getting paid shouldn't be      </p>
            </div>
          </div>
        </div>


        <div className="relative max-w-full h-full ">
          <div className="absolute inset-0 bg-cover bg-center bg-pos-section"></div>
          <div className="relative flex lg:flex-row sm:flex-col sm:items-center gap-4 items-start justify-between lg:h-[450px] lg:px-24 sm:mb-[100px] lg:mb-[200px]">


            <div className="flex-1 gap-4 flex-col items-center justify-between">
              <div className="">
                <div className="flex flex-col  items-center justify-center flex-wrap  lg:px-[20px]">
                  <div className="">
                    <img src={Image.payment1} alt="handphone" />

                  </div>
                  <p className="text-[#011F44] font-inter text-center font-regular text-[30px] sm:px-[10px] sm:items-center sm:text-center">
                    Online Checkout
                  </p>
                  <p className="text-[#011F44]  font-inter text-center font-regular text-[16px]sm:px-[10px] sm:items-center sm:text-center ">
                    Our online checkout features our smart payment ordering system, incredible speed, and beautiful simplicity
                  </p>

                </div>
              </div>
              <div className="">
                <div className="flex flex-col items-center justify-center flex-wrap  lg:px-[50px]">
                  <div className="">
                    <img src={Image.payment2} alt="handphone" />

                  </div>
                  <p className="text-[#011F44] font-inter text-center font-regular text-[30px] sm:px-[10px] sm:items-center sm:text-center">
                    Accept payments
                  </p>
                  <p className="text-[#011F44]  font-inter text-center font-regular text-[16px] sm:px-[10px] sm:items-center sm:text-center">
                    Easily receive money from anyone, anywhere in the world via our multitude of secure payment methods </p>

                </div>
              </div>
            </div>
            <div className="  w-[330px] lg:h-[330px] object-fit">
              <img src={Image.phone} alt="handphone" />
            </div>
            <div className="flex-1 gap-4 flex-col items-center justify-between">
              <div className="">
                <div className="flex flex-col  items-center justify-center flex-wrap  lg:px-[50px]">

                  <img src={Image.payment3} alt="handphone" />


                  <p className="text-[#011F44] font-inter text-center font-regular text-[30px] sm:px-[10px] sm:items-center sm:text-center">
                    QR code
                  </p>
                  <p className="text-[#011F44]  font-inter text-center font-regular text-[16px] sm:px-[10px] sm:items-center sm:text-center">
                    Easily receive money from anyone, anywhere in the world via quick scanning  Our online checkout features our smart payment ordering system, incredible speed, and beautiful simplicity
                  </p>

                </div>
              </div>
              <div className="">
                <div className="flex flex-col  items-center justify-center flex-wrap  lg:px-[50px]">
                  <div className="">
                    <img src={Image.payment4} alt="handphone" />

                  </div>
                  <p className="text-[#011F44] font-inter text-center font-regular text-[30px] sm:px-[10px] sm:items-center sm:text-center">
                    Transfers (Payout)
                  </p>
                  <p className="text-[#011F44]  font-inter text-center font-regular text-[16px] sm:px-[10px] sm:items-center sm:text-center">
                    Make single or bulk transfers to bank accounts from your Paysys dashboard. </p>

                </div>
              </div>
            </div>
          </div></div>

        <div className="lg:px-[7%] text-center lg:py-[100px] grid gap-[100px] sm:mx-[10px]">
          <div className=" bg-[#00ADEF] border  sm:py-8  rounded-[40px] flex  lg:gap-16 lg:flex-row sm:flex-col items-center justify-between gap-4 max-w-full  lg:mt-8 lg:px-12">
            <div className="flex flex-col items-left justify-center flex-wrap lg:w-[50%]   lg:h-[500px] ">
              <p className="text-[#ffffff] text-left font-inter font-bold text-[64px] sm:text-[38px] leading-tight sm:px-[10px] sm:items-center sm:text-center">
                <span className="text-[#F8BC3B]"> Connect</span> to your easy transaction life
              </p>
              <p className="text-[#ffffff] text-left font-inter font-regular text-[18px] sm:px-[10px] sm:items-center sm:text-center">
                We create solutions that enable individuals and communities prosper across Nigeria.
              </p>
            </div>
            <div className="mb-8">
              <img src={Image.checkbox} alt="handphone" />

            </div>
          </div>

        </div>

        <div className="flex flex-col items-center justify-center flex-wrap ">
          <div className="text-[0.7rem] sm:text-extraSmall text-nowrap bg-[#F68D27] rounded-full px-4 py-1 sm:mt-12">

            STATE AVAILABILITY

          </div>
          <h1 className="text-[48px] sm:text-[30px]  font-inter font-regular text-center leading-extraTight">
            We've got you covered <br />in
            over 30 states
          </h1>
          <p className="text-[16px]  font-inter font-regular text-center leading-extraTight">
            Find all the states we cover
          </p>
          <div className="flex gap-4 my-2">
            <button className="border border-textGrey bg-textGrey text-white rounded-full px-2 py-2">
              <img src={Image.arrowright} alt="people" className="m-auto h-4 w-4" />
            </button>
          </div>
          <div className="md:w-3/4 rounded-lg p-[32px] grid gap-[30px] m-auto">
            <img src={Image.map} alt="people" className="m-auto" />

          </div>

        </div>
        <Footer />
      </main>


    </div>
  );
};

export default LandingPage;